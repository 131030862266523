import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache, split } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
// import { onError } from '@apollo/client/link/error'
import { WebSocketLink } from '@apollo/client/link/ws'
import { RetryLink } from '@apollo/client/link/retry'
import { setContext } from '@apollo/client/link/context'
import { toIdValue } from 'apollo-utilities'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import io from 'socket.io-client'
import Cookies from 'universal-cookie'

import { evCompareCacheListing } from '../assets/javascripts/function'

import { scanUser } from '../pages/users/partials/log'
import { expiredUser } from '../pages/users/partials/logout'

export const forstokCookies = new Cookies()

const isStatic = window.location.pathname === '/static/prints'

const getToken = () => {
  const urlParams = new URLSearchParams(window.location.search),
        tokenParams = urlParams.get('token') || ''
  const Retoken = isStatic ? (tokenParams || '') : forstokCookies.get('token')
  return Retoken
}

const withRetries = new RetryLink({
  delay: {
    max: 30 * 1000,
  },
  attempts: (count, operation, error) => {
    if (error && error.toString().indexOf('AbortError') !== -1) {
      console.log(error.toString(), "error", operation)
      count = 0
      return false
    }
    if(count >= 3) {
      console.log(`Retrying operation ${operation.operationName} (Attempt ${count})`)
      intrigueToken()
      if(count >= 5) return false
    }
    return !!error
  }
})

const link = createHttpLink({uri: process.env.REACT_APP_GRAPHQL_URL})

const wsClient = new SubscriptionClient('wss://apollo-subscription.forstok.com/graphql', {
  reconnect: true,
  timeout: 20000
})
const wsLink = new WebSocketLink(wsClient)

wsClient.onConnected(() => console.log('websocket connected!!'))
wsClient.onDisconnected(() => console.log('websocket disconnected!!'))
wsClient.onReconnected(() => console.log('websocket reconnected!!'))

const intrigueToken = () => {
  const token = getToken()
  if(!token && !isStatic) {
    const callbackToken = () => {
      expiredUser()
    }
    scanUser().then((result) => {
      if(!result) callbackToken()
    }).catch(error => {
      console.error('Error Checking Login', error)
      callbackToken()
    })
  }
}

const forstokHttpLink = ApolloLink.from([
  withRetries,
  link
])
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  forstokHttpLink,
)
const forstokAuthLink = setContext((_, { headers }) => {
  const token = getToken()
  intrigueToken()
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    }
  }
})
export const cache = new InMemoryCache({
  cacheRedirects: {
    Query: {
      order: (_, { id }) =>
        toIdValue(cache.config.dataIdFromObject({ __typename: 'Order', id })),
      package: (_, { id }) =>
        toIdValue(cache.config.dataIdFromObject({ __typename: 'Package', id })),
      picklist: (_, { id }) =>
        toIdValue(cache.config.dataIdFromObject({ __typename: 'Picklist', id })),
      fulfillment: (_, { id }) =>
        toIdValue(cache.config.dataIdFromObject({ __typename: 'OrderFulfillment', id })),
      invoice: (_, { id }) =>
        toIdValue(cache.config.dataIdFromObject({ __typename: 'OrderInvoice', id })),
      paymentReceiveds: (_, { id }) =>
        toIdValue(cache.config.dataIdFromObject({ __typename: 'PaymentReceived', id })),
      products: (_, { id }) =>
        toIdValue(cache.config.dataIdFromObject({ __typename: 'Product', id })),
      listings: (_, { id }) =>
        toIdValue(cache.config.dataIdFromObject({ __typename: 'ProductListing', id })),
      return: (_, { id }) =>
        toIdValue(cache.config.dataIdFromObject({__typename: 'Return', id}))
    }
  },
  typePolicies: {
    Query: {
      fields: {
        warehouses: {
          merge(existing, incoming){
            return incoming
          }
        },
        orders: {
          merge(existing, incoming){
            return {...existing, ...incoming}
          }
        },
        packages: {
          merge(existing, incoming){
            return {...existing, ...incoming}
          }
        },
        picklists: {
          merge(existing, incoming){
            return {...existing, ...incoming}
          }
        },
        fulfillments: {
          merge(existing, incoming){
            return {...existing, ...incoming}
          }
        },
        profileActivities: {
          merge(existing, incoming){
            return {...existing, ...incoming}
          }
        },
        invoices: {
          merge(existing, incoming){
            return {...existing, ...incoming}
          }
        },
        paymentReceiveds: {
          merge(existing, incoming){
            return {...existing, ...incoming}
          }
        },
        products: {
          merge(existing, incoming){
            return {...existing, ...incoming}
          }
        },
        variants: {
          merge(existing, incoming){
            return {...existing, ...incoming}
          }
        },
        listings: {
          merge(existing, incoming, { args, readField }){
            return evCompareCacheListing(existing, incoming, args, readField) ? {...existing, ...incoming} : existing
          }
        },
        listingBrands: {
          merge(existing, incoming){
            return {...existing, ...incoming}
          }
        },
        categoryMappings: {
          merge(existing, incoming){
            return {...existing, ...incoming}
          }
        },
        order: {
          itemLines : {
            merge(existing, incoming){
              return incoming
            }
          }
        },
        financeTransactions: {
          merge(existing, incoming){
            return {...existing, ...incoming}
          }
        },
        qtyInventories: {
          merge(existing, incoming){
            return {...existing, ...incoming}
          }
        },
        GetProductBundles: {
          merge(existing, incoming){
            return incoming
          }
        },
        promotions: {
          merge(existing, incoming){
            return {...existing, ...incoming}
          }
        },
        listingTotalSkus: {
          merge(existing, incoming){
            return {...existing, ...incoming}
          }
        },
        stockHistoryList: {
          merge(existing, incoming){
            return {...existing, ...incoming}
          }
        },
        priceAdjustments: {
          merge(existing, incoming){
            return {...existing, ...incoming}
          }
        },
        priceHistories: {
          merge(existing, incoming){
            return {...existing, ...incoming}
          }
        },
        returnOrders: {
          merge(existing, incoming){
            return {...existing, ...incoming}
          }
        }
      }
    },
    Warehouses: {
      keyFields: ['id'],
    },
    Accounts: {
      keyFields: ['id', ['name']],
    },
    WarehouseInventoryPromotion: {
      keyFields: false
    },
    WarehouseInventoryReservedOrder: {
      keyFields: false
    },
    Bin: {
      keyFields: false
    },
    Channels: {
      keyFields: ['navbarId']
    }
  },
  addTypename: true
})

export const client = new ApolloClient({
  link: forstokAuthLink.concat(splitLink),
  cache,
  queryDeduplication: false
})

export const socket = io.connect('wss://socket.forstok.com')